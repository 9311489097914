<template>
  <div>
    <modal
      ref="modal"
      :modal-title="$t('business_subscription_modal.title')"
      modal-css-class="business-plan-modal"
      @modal-shown="onModalShown"
    >
      <template #modal-content>
        <loading-indicator
          v-if="isLoading"
          :message="$t('business_subscription_modal.loading_details')"
          :use-flexbox="true"
        />
        <template v-if="!isLoading && !detailsLoaded">
          <alert
            :message="detailsLoadingError"
            :dismissable="false"
          />
        </template>
        <template v-else-if="!isLoading && detailsLoaded">
          <div class="form-group">
            <label
              for="businessUserCount"
              class="required"
            >
              {{ $t('business_subscription_modal.number_of_users') }}
            </label>
            <div class="range-input-group">
              <input
                id="businessUserCount"
                v-model="userCountSliderValue"
                type="range"
                class="custom-range"
                min="0"
                :max="userCountSliderMax"
                @change="updateSliderUserCount"
                @input="updateSliderUserCount"
              >
              <div class="value">
                <input
                  ref="manualUserCount"
                  v-model="manualUserCount"
                  type="number"
                  class="form-control form-control-sm"
                  @change="updateManualUserCount"
                >
              </div>
            </div>
          </div>

          <div class="form-group">
            <label
              for="businessStorage"
              class="required"
            >
              {{ $t('business_subscription_modal.data_amount') }}
            </label>
            <div class="range-input-group">
              <input
                id="businessStorage"
                v-model="storageLimitSliderValue"
                type="range"
                class="custom-range"
                min="0"
                :max="storageLimitSliderMax"
                @change="updateSliderStorageLimit"
                @input="updateSliderStorageLimit"
              >
              <div class="value">
                <input
                  ref="manualStorageLimit"
                  v-model="manualStorageLimit"
                  type="number"
                  class="form-control form-control-sm"
                  @change="updateManualStorageLimit"
                >
              </div>
            </div>
          </div>
          <p class="monthly-price">
            {{ $t('business_subscription_modal.price_per_month') }} <span class="price">{{ pricePerMonth.toFixed(2) }} EUR</span>
          </p>

          <h4 v-if="calculatorMode">{{ $t('business_subscription_modal.calculator.subscription_period') }}</h4>
          <h4 v-else>{{ $t('business_subscription_modal.subscription_period') }}</h4>
          <div class="subscription-periods">
            <label
              v-for="period of subscriptionPeriods"
              :key="period.id"
              :class="['period', { selected: period.id == subscriptionPeriod }]"
            >
              <span class="rb">
                <input
                  v-model="subscriptionPeriod"
                  type="radio"
                  name="subscription_period"
                  :value="period.id"
                >
                <span />
              </span>
              <span class="title">{{ period.title }}</span>
            </label>
          </div>

          <template v-if="calculatorMode">
            <p class="monthly-price">
              {{ $t('business_subscription_modal.total_price') }} <span class="price">{{ totalPrice.toFixed(2) }} EUR</span>
            </p>
            <div class="price-notice">
              {{ $t('business_subscription_modal.calculator.price_notice') }}
            </div>
          </template>
          <template v-else>
            <h4 class="mt-4">{{ $t('business_subscription_modal.billing_details.title') }}</h4>

            <div
              v-for="billingType of billingTypes"
              :key="billingType.id"
              class="form-group custom-control custom-radio custom-control-inline"
            >
              <input
                :id="`billingType${billingType.id}`"
                v-model="billingTypeId"
                :value="billingType.id"
                type="radio"
                name="billing_type"
                class="custom-control-input"
              >
              <label
                class="custom-control-label"
                :for="`billingType${billingType.id}`"
              >
                {{ billingType.title }}
              </label>
            </div>
            <div class="form-row">
              <div class="form-group col-6">
                <label
                  for="billing_first_name"
                  class="required"
                >
                  {{ $t('business_subscription_modal.billing_details.first_name') }}
                </label>
                <input
                  id="billing_first_name"
                  v-model="billingDetails.firstName"
                  type="text"
                  class="form-control"
                >
              </div>
              <div class="form-group col-6">
                <label
                  for="billing_last_name"
                  class="required"
                >
                  {{ $t('business_subscription_modal.billing_details.last_name') }}
                </label>
                <input
                  id="billing_last_name"
                  v-model="billingDetails.lastName"
                  type="text"
                  class="form-control"
                >
              </div>
            </div>
            <div class="form-row">
              <div
                v-if="isCompany"
                class="form-group col-12"
              >
                <label
                  for="billing_company_name"
                  class="required"
                >
                  {{ $t('business_subscription_modal.billing_details.company_name') }}
                </label>
                <input
                  id="billing_company_name"
                  v-model="billingDetails.companyName"
                  type="text"
                  class="form-control"
                >
              </div>
              <div
                v-else
                class="form-group col-6"
              >
                <label
                  for="billing_id_no"
                  class="required"
                >
                  {{ $t('business_subscription_modal.billing_details.id') }}
                </label>
                <input
                  id="billing_id_no"
                  v-model="billingDetails.idNo"
                  type="text"
                  class="form-control"
                >
              </div>
            </div>
            <div
              v-if="isCompany"
              class="form-row"
            >
              <div class="form-group col-6">
                <label
                  for="billing_company_reg_no"
                  class="required"
                >
                  {{ $t('business_subscription_modal.billing_details.company_reg_no') }}
                </label>
                <input
                  id="billing_company_reg_no"
                  v-model="billingDetails.companyRegNo"
                  type="text"
                  class="form-control"
                >
              </div>
              <div class="form-group col-6">
                <label for="billing_company_vat_no">
                  {{ $t('business_subscription_modal.billing_details.company_vat_no') }}
                </label>
                <input
                  id="billing_company_vat_no"
                  v-model="billingDetails.companyVatNo"
                  type="text"
                  class="form-control"
                >
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-12">
                <label
                  for="billing_address"
                  class="required"
                >
                  {{ $t('business_subscription_modal.billing_details.address') }}
                </label>
                <input
                  id="billing_address"
                  v-model="billingDetails.address"
                  type="text"
                  class="form-control"
                >
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-6">
                <label
                  for="billing_country"
                  class="required"
                >
                  {{ $t('business_subscription_modal.billing_details.country') }}
                </label>
                <select
                  id="billing_country"
                  v-model="billingDetails.countryCode"
                  class="custom-select"
                >
                  <option />
                  <option
                    v-for="bCountry of countries"
                    :key="bCountry.code"
                    :value="bCountry.code"
                  >
                    {{ bCountry.name }}
                  </option>
                </select>
              </div>
            </div>
            <template v-if="allowPromoCode">
              <div
                v-if="hasPromoCode"
                class="form-row"
              >
                <div class="form-group col-6">
                  <label for="promo_code">
                    {{ $t('business_subscription_modal.billing_details.promo_code') }}
                  </label>
                  <input
                    v-model="billingDetails.promoCode"
                    type="text"
                    class="form-control"
                    readonly="readonly"
                  >
                </div>
                <div class="form-group col-6 no-label">
                  <button
                    class="m-btn m-btn-form-group"
                    type="button"
                    @click="removePromoCode"
                  >
                    {{ $t('business_subscription_modal.billing_details.remove_promo_code') }}
                  </button>
                </div>
              </div>
              <div
                v-else
                class="form-row"
              >
                <div class="form-group col-6">
                  <label for="promo_code">
                    {{ $t('business_subscription_modal.billing_details.promo_code') }}
                  </label>
                  <input
                    v-model="tempPromoCode"
                    type="text"
                    class="form-control"
                  >
                </div>
                <div class="form-group col-6 no-label">
                  <button
                    v-if="!checkingPromoCode"
                    class="m-btn m-btn-form-group"
                    type="button"
                    @click="applyPromoCode"
                  >
                    {{ $t('business_subscription_modal.billing_details.apply_promo_code') }}
                  </button>
                  <small
                    v-else
                    class="form-text"
                  >
                    Checking promo code ...
                  </small>
                </div>
              </div>
            </template>

            <p class="monthly-price">
              <template v-if="discount > 0">
                {{ $t('business_subscription_modal.total_price_without_discount') }} <span class="price">{{ totalPriceWithoutDiscount.toFixed(2) }} EUR</span>
                <br>
                {{ $t('business_subscription_modal.discount') }} <span class="price">{{ discount.toFixed(2) }} EUR</span>
                <br>
              </template>
              {{ totalPriceLabel }} <span class="price">{{ totalPrice.toFixed(2) }} EUR</span>
              <template v-if="vatReverseCharge">
                <br>
                {{ $t('business_subscription_modal.billing_details.vat_reverse_charge') }}
              </template>
            </p>

            <div class="accept-terms">
              <div class="custom-control custom-checkbox">
                <input
                  id="buyTermsAccepted"
                  v-model="billingDetails.termsAccepted"
                  type="checkbox"
                  class="custom-control-input"
                >
                <label
                  class="custom-control-label"
                  for="buyTermsAccepted"
                >
                  {{ $t('business_subscription_modal.i_have_accepted') }}
                  <a
                    :href="termsOfServiceUrl"
                    target="_blank"
                  >
                    {{ $t('business_subscription_modal.terms_of_service') }}
                  </a>
                </label>
              </div>
              <div class="custom-control custom-checkbox">
                <input
                  id="distanceAgreementAccepted"
                  v-model="billingDetails.distanceAgreementAccepted"
                  type="checkbox"
                  class="custom-control-input"
                >
                <label
                  class="custom-control-label"
                  for="distanceAgreementAccepted"
                >
                  {{ $t('business_subscription_modal.i_have_accepted') }}
                  <a
                    :href="distanceAgreementUrl"
                    target="_blank"
                  >
                    {{ $t('business_subscription_modal.distance_agreement') }}
                  </a>
                </label>
              </div>
            </div>
            <div class="card-payments">
              <img
                src="../../images/theme/payments-paysera-logo.png"
                alt="Payments"
              >
            </div>
          </template>
        </template>
      </template>
      <template #modal-footer>
        <a
          v-if="calculatorMode && !isLoading && detailsLoaded"
          :href="subscribeUrl || '#'"
          class="m-btn m-btn-primary"
        >
          {{ $t('business_subscription_modal.buy') }}
        </a>
        <button
          v-else-if="!calculatorMode && !isLoading && detailsLoaded"
          type="button"
          class="m-btn m-btn-primary"
          @click="onBuy"
        >
          {{ $t('business_subscription_modal.buy') }}
        </button>
        <button
          type="button"
          class="m-btn m-btn-cancel"
          data-dismiss="modal"
        >
          {{ $t('business_subscription_modal.cancel') }}
        </button>
      </template>
    </modal>
    <modal-alert
      ref="validationAlertModal"
      :modal-title="$t('business_subscription_modal.error.title')"
      :modal-message="validationError"
      :modal-buttons="cancelAlertButtons"
      @modal-button-click="onCancelAlertButton"
    />
  </div>
</template>
<script lang="ts">
  import { defineComponent } from 'vue'
  import Modal from './modal.vue'
  import { getAxiosErrorMessage, getFloat, isNonEmptyString } from '../js-utils'
  import axios from 'axios'
  import Rails from '@rails/ujs'
  import Jsona from 'jsona'
  import validate from "validate.js"
  import { default as ModalAlert, ModalButton } from './modal-alert.vue'
  import LoadingIndicator from './loading-indicator.vue'
  import Alert from './alert.vue'

  interface SubscriptionPeriod {
    id: number;
    title: string;
  }

  enum BillingType {
    individual = 1,
    company = 2
  }

  interface BillingElement {
    id: BillingType;
    title: string;
  }

  interface BillingCountry {
    code: string;
    name: string;
    vatCompany: number | null;
    vatIndividual: number | null;
  }

  // export default (Vue as VueConstructor<
  //   Vue & {
  //     $refs: {
  //       modal: InstanceType<typeof Modal>;
  //       manualUserCount: HTMLInputElement;
  //       validationAlertModal: InstanceType<typeof ModalAlert>;
  //     };
  //   }
  // >).extend({
  export default defineComponent({
    components: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      Alert, LoadingIndicator, Modal, ModalAlert
    },
    props: {
      subscriptionDetailsUrl: { type: String, default: null },
      calculatorMode: { type: Boolean, default: false }
    },
    data () {
      return {
        tempPromoCode: '',
        checkingPromoCode: false,
        promoCodeDetails: {
          type: 0,
          discount: 0,
        },
        userPricing: [] as [number, number][],
        dataPricing: [] as [number, number][],
        userCount: 0,
        storageLimit: 0,
        subscriptionPeriods: [] as SubscriptionPeriod[],
        subscriptionPeriod: 1,
        validationError: null as string | null,
        billingTypes: [] as BillingElement[],
        billingTypeId: BillingType.individual,
        billingDetails: {
          firstName: '',
          lastName: '',
          idNo: '',
          companyName: '',
          companyRegNo: '',
          companyVatNo: '',
          address: '',
          countryCode: '',
          promoCode: '',
          termsAccepted: false,
          distanceAgreementAccepted: false
        },
        countries: [] as BillingCountry[],
        cancelAlertButtons: [
          { title: 'Ok', cssClass: 'm-btn-cancel', value: 'dontCancel' }
        ] as ModalButton[],
        termsOfServiceUrl: '#',
        distanceAgreementUrl: '#',
        detailsLoaded: false,
        isLoading: true,
        detailsLoadingError: null as string | null,
        subscribeUrl: null as string | null,
        checkPromoCodeUrl: null as string | null,
      }
    },
    computed: {
      userCountSliderMax(): number {
        return this.userPricing.length > 0 ? this.userPricing.length - 1 : 0
      },

      userCountSliderValue: {
        get(): number {
          const idx = this.getValueIdx(this.userCount, this.userPricing)
          return idx || 0
        },

        set(): void {
          // pass
        }
      },

      manualUserCount: {
        get(): number {
          return this.userCount
        },

        set(): void {
          // pass
        }
      },

      storageLimitSliderMax(): number {
        return this.dataPricing.length > 0 ? this.dataPricing.length - 1 : 0
      },

      storageLimitSliderValue: {
        get(): number {
          const idx = this.getValueIdx(this.storageLimit, this.dataPricing)
          return idx || 0
        },

        set(): void {
          // pass
        }
      },

      manualStorageLimit: {
        get(): number {
          return this.storageLimit
        },

        set(): void {
          // pass
        }
      },

      pricePerMonth(): number {
        const userPrice = this.userPricing.find((element) => element[0] == this.userCount)
        const dataPrice = this.dataPricing.find((element) => element[0] == this.storageLimit)
        if (userPrice && dataPrice) {
          return (userPrice[1] + dataPrice[1])
        }
        return 0.0
      },

      isCompany(): boolean {
        return this.billingTypeId == BillingType.company
      },

      country(): BillingCountry {
        const country = this.countries.find((element) => element.code == this.billingDetails.countryCode)
        if (country) {
          return country
        } else {
          return {
            code: '',
            name: '',
            vatCompany: null,
            vatIndividual: null,
          }
        }
      },

      vatReverseCharge(): boolean {
        if (this.billingTypeId == BillingType.company) {
          if (this.country && this.country.vatCompany == null) {
            return true
          }
          return false
        } else {
          return false
        }
      },

      vat(): number | null {
        if (this.billingTypeId == BillingType.company) {
          return this.country.vatCompany
        } else if (this.billingTypeId == BillingType.individual) {
          return this.country.vatIndividual
        }
        return null
      },

      totalPriceLabel(): string {
        if (this.vat) {
          return this.$t('business_subscription_modal.total_price_vat', { vatPercent: (this.vat * 100).toFixed() })
        }
        return this.$t('business_subscription_modal.total_price')
      },

      totalPriceWithoutDiscount(): number {
        return Math.round(this.pricePerMonth * this.subscriptionPeriod * 100) / 100.0
      },

      totalPrice(): number {
        const price = this.totalPriceWithoutDiscount - this.discount
        if (this.vat) {
          return Math.round(price * (1 + this.vat) * 100) / 100.0
        }
        return price
      },

      allowPromoCode(): boolean {
        return isNonEmptyString(this.checkPromoCodeUrl)
      },

      hasPromoCode(): boolean {
        return isNonEmptyString(this.billingDetails.promoCode)
      },

      discount(): number {
        const price = this.totalPriceWithoutDiscount
        let discount = 0
        if (this.hasPromoCode) {
          if (this.promoCodeDetails.type == 1) {
            // discount by amount
            discount = this.promoCodeDetails.discount
            if (discount > price) {
              discount = price
            }
          } else if (this.promoCodeDetails.type == 2) {
            // discount by percentage
            discount = price * this.promoCodeDetails.discount / 100.0
          }
        }
        return Math.round(discount * 100) / 100.0
      }
    },

    methods: {
      showModal(): void {
        this.$refs.modal.show()
      },

      onModalShown(): void {
        this.isLoading = true
        this.detailsLoaded = false
        this.detailsLoadingError = null

        // reset upgrade data
        this.userPricing = []
        this.dataPricing = []
        this.countries = []

        this.subscribeUrl = null
        this.termsOfServiceUrl = '#'
        this.distanceAgreementUrl = '#'
        this.billingTypeId = BillingType.individual
        this.billingDetails = {
          firstName: '',
          lastName: '',
          idNo: '',
          companyName: '',
          companyRegNo: '',
          companyVatNo: '',
          address: '',
          countryCode: '',
          promoCode: '',
          termsAccepted: false,
          distanceAgreementAccepted: false
        }

        axios.get(this.subscriptionDetailsUrl)
          .then((response) => {

            try {
              const parsedData = new Jsona().deserialize(response.data)
              if (!Array.isArray(parsedData)) {
                if (Array.isArray(parsedData.u)) {
                  parsedData.u.forEach((value) => {
                    const price = parseFloat(value[1].toString())
                    if (!isNaN(price)) {
                      this.userPricing.push([value[0], price])
                    }
                  })
                }
                if (Array.isArray(parsedData.d)) {
                  parsedData.d.forEach((value) => {
                    const price = parseFloat(value[1].toString())
                    if (!isNaN(price)) {
                      this.dataPricing.push([value[0], price])
                    }
                  })
                }

                if (Array.isArray(parsedData.countries)) {
                  parsedData.countries.forEach((value) => {
                    this.countries.push({
                      code: value[0],
                      name: value[1],
                      vatCompany: getFloat(value[2]),
                      vatIndividual: getFloat(value[3])
                    })
                  })
                }

                if (Array.isArray(parsedData.subscriptionPeriods)) {
                  this.subscriptionPeriods = parsedData.subscriptionPeriods
                }

                if (Array.isArray(parsedData.billingTypes)) {
                  this.billingTypes = parsedData.billingTypes
                }

                this.userCount = this.userPricing[0][0]
                this.storageLimit = this.dataPricing[0][0]

                this.billingTypeId = parsedData.billingTypeId
                this.billingDetails.firstName = parsedData.firstName
                this.billingDetails.lastName = parsedData.lastName
                this.billingDetails.idNo = parsedData.idNo
                this.billingDetails.companyName = parsedData.companyName
                this.billingDetails.companyRegNo = parsedData.companyRegNo
                this.billingDetails.companyVatNo = parsedData.companyVatNo
                this.billingDetails.address = parsedData.address
                this.billingDetails.countryCode = parsedData.countryCode

                if (parsedData.links) {
                  if (isNonEmptyString(parsedData.links.subscribe)) {
                    this.subscribeUrl = parsedData.links.subscribe
                  }

                  if (isNonEmptyString(parsedData.links.terms_of_service)) {
                    this.termsOfServiceUrl = parsedData.links.terms_of_service
                  }
                  if (isNonEmptyString(parsedData.links.distance_agreement)) {
                    this.distanceAgreementUrl = parsedData.links.distance_agreement
                  }

                  this.checkPromoCodeUrl = null
                  if (isNonEmptyString(parsedData.links.check_promo_code)) {
                    this.checkPromoCodeUrl = parsedData.links.check_promo_code
                  }
                }

                this.detailsLoaded = true
              }
            } catch {
              this.detailsLoadingError = this.$t('business_subscription_modal.loading_details_failed')
            }
          })
          .catch((error) => {
            this.detailsLoadingError = getAxiosErrorMessage(error, this.$t('business_subscription_modal.loading_details_failed'))
          })
          .then(() => {
            this.isLoading = false
          })
      },

      updateManualUserCount(e: Event): void {
        if (e.target instanceof HTMLInputElement) {
          const closest = this.getClosestValue(e.target.value, this.userPricing, this.userCount)
          this.userCount = closest
          e.target.value = this.userCount.toString()
        }
      },

      updateSliderUserCount(e: Event): void {
        if (e.target instanceof HTMLInputElement && this.userPricing.length > 0) {

          const v = parseInt(e.target.value)
          if (v != null && !isNaN(v) && this.userPricing.length > v) {
            this.userCount = this.userPricing[v][0]
          }
        }
      },

      updateManualStorageLimit(e: Event): void {
        if (e.target instanceof HTMLInputElement) {
          const closest = this.getClosestValue(e.target.value, this.dataPricing, this.storageLimit)
          this.storageLimit = closest
          e.target.value = this.storageLimit.toString()
        }
      },

      updateSliderStorageLimit(e: Event): void {
        if (e.target instanceof HTMLInputElement && this.dataPricing.length > 0) {

          const v = parseInt(e.target.value)
          if (v != null && !isNaN(v) && this.dataPricing.length > v) {
            this.storageLimit = this.dataPricing[v][0]
          }
        }
      },

      getClosestValue(inputValue: string, listOfValues: [number, number][], defaultValue: number): number {
        const v = parseInt(inputValue)
        if (!isNaN(v)) {
          let minV = listOfValues[0][0]
          for (const val of listOfValues) {
            if (val[0] == v) {
              return val[0] // found exact value
            }

            if (val[0] <= v && val[0] > minV) {
              minV = val[0]
            }
          }
          return minV
        }
        return defaultValue
      },

      getValueIdx(value: number, listOfValues: [number, number][]): number | null {
        const idx = listOfValues.findIndex((val) => val[0] == value)
        return idx == -1 ? null : idx
      },

      onBuy(): void {
        if (!isNonEmptyString(this.subscribeUrl)) {
          return
        }

        // validate data
        const constraints: any = {
          firstName: {
            presence: { allowEmpty: false, message: `^${this.$t('business_subscription_modal.error.first_name_required')}` }
          },
          lastName: {
            presence: { allowEmpty: false, message: `^${this.$t('business_subscription_modal.error.last_name_required')}` }
          }
        }

        if (this.billingTypeId == BillingType.company) {
          constraints['companyName'] = {
            presence: { allowEmpty: false, message: `^${this.$t('business_subscription_modal.error.company_name_required')}` }
          },
          constraints['companyRegNo'] = {
            presence: { allowEmpty: false, message: `^${this.$t('business_subscription_modal.error.company_reg_no_required')}` }
          }
        }

        constraints['address'] = {
          presence: { allowEmpty: false, message: `^${this.$t('business_subscription_modal.error.address_required')}` }
        }
        constraints['country'] = {
          presence: { allowEmpty: false, message: `^${this.$t('business_subscription_modal.error.country_required')}` }
        }

        const billingData = {
          firstName: this.billingDetails.firstName,
          lastName: this.billingDetails.lastName,
          id: this.billingDetails.idNo,
          companyName: this.billingDetails.companyName,
          companyRegNo: this.billingDetails.companyRegNo,
          companyVatNo: this.billingDetails.companyVatNo,
          address: this.billingDetails.address,
          country: this.billingDetails.countryCode,
        }

        const validationErrors = validate(billingData, constraints)
        this.validationError = null
        if (validationErrors) {
          const errorKey = Object.keys(validationErrors)[0]
          this.validationError = validationErrors[errorKey][0]
        } else if (!this.billingDetails.termsAccepted) {
          this.validationError = this.$t('business_subscription_modal.error.accept_terms_of_service')
        } else if (!this.billingDetails.distanceAgreementAccepted) {
          this.validationError = this.$t('business_subscription_modal.error.accept_distance_aggreement')
        }

        if (isNonEmptyString(this.validationError)) {
          this.$refs.validationAlertModal.show()
          return
        }

        const subscriptionData = {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          user_count: this.userCount,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          storage: this.storageLimit,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          period: this.subscriptionPeriod,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          billing_type_id: this.billingTypeId,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          first_name: this.billingDetails.firstName,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          last_name: this.billingDetails.lastName,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          id_no: this.billingDetails.idNo,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          company_name: this.billingDetails.companyName,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          company_reg_no: this.billingDetails.companyRegNo,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          company_vat_no: this.billingDetails.companyVatNo,
          address: this.billingDetails.address,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          country_code: this.billingDetails.countryCode,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          promo_code: this.billingDetails.promoCode
        }

        axios.post(this.subscribeUrl, subscriptionData, {
        headers: {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          'X-Requested-With': 'XMLHttpRequest',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          'X-CSRF-Token': Rails.csrfToken(),
          // eslint-disable-next-line @typescript-eslint/naming-convention
          'Accept': 'application/json'
        },
      })
        .then((response): void => {
          if (response.data.url) {
            window.location.href = response.data.url
          } else if (isNonEmptyString(response.data.error)) {
            alert(response.data.error)
          } else {
            alert(this.$t('business_subscription_modal.error.error_occured'))
          }
        })
        .catch((error): void => {
          let errorMessage = this.$t('business_subscription_modal.error.error_occured')
          if (error) {
            if (isNonEmptyString(error.message)) {
              errorMessage = error.message
            } else if (isNonEmptyString(error)) {
              errorMessage = error
            }
          }
          alert(errorMessage)
        })
      },

      onCancelAlertButton (): void {
        if (this.$refs.validationAlertModal) {
          this.$refs.validationAlertModal.hide()
        }
      },

      applyPromoCode(): void {
        if (this.checkingPromoCode || !isNonEmptyString(this.checkPromoCodeUrl)) {
          return
        }

        this.checkingPromoCode = true
        axios.post(this.checkPromoCodeUrl, {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          promo_code: this.tempPromoCode
        }, {
          headers: {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'X-Requested-With': 'XMLHttpRequest',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'X-CSRF-Token': Rails.csrfToken(),
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'Accept': 'application/json'
          },
        })
        .then((response): void => {
          if (response.data.promo_code) {
            this.promoCodeDetails.type = response.data.promo_code.type
            this.promoCodeDetails.discount = getFloat(response.data.promo_code.discount) || 0
            this.billingDetails.promoCode = this.tempPromoCode
            this.tempPromoCode = ''
          }
        })
        .catch((error): void => {
          alert(getAxiosErrorMessage(error))
        })
        .then(() => {
          this.checkingPromoCode = false
        })
      },

      removePromoCode(): void {
        this.billingDetails.promoCode = ''
        this.tempPromoCode = ''
        this.promoCodeDetails = {
          type: 0,
          discount: 0,
        }
      }
    }
  })
</script>
