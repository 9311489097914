<template>
  <ModalBase
    ref="modal"
    modal-css-class="modal-alert"
    :modal-title="modalTitle"
    @modal-shown="onModalShown"
  >
    <template #modal-content>
      <Alert
        :m-alert="true"
        :type="alertType"
        :dismissable="false"
        :message="modalMessage"
      />
    </template>
    <template #modal-footer>
      <button
        v-for="(button, buttonIdx) of modalButtons"
        :key="buttonIdx"
        type="button"
        :class="['m-btn', button.cssClass]"
        @click.prevent="onButtonClick(button)"
      >
        {{ button.title }}
      </button>
    </template>
  </ModalBase>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import ModalBase from './modal-base.vue'
import Alert from './alert.vue'

const modal = ref<InstanceType<typeof ModalBase>>()

interface Props {
  modalTitle?: string
  modalMessage?: string
  modalButtons?: ModalButton[]
  alertType?: string
}

withDefaults(defineProps<Props>(), {
  modalTitle: 'Alert',
  modalMessage: '',
  modalButtons: () => [],
  alertType: 'info'
})

const emit = defineEmits<{
  (e: 'modal-shown'): void,
  (e: 'modal-button-click', button: ModalButton): void
}>()

const onModalShown = () => emit('modal-shown')
const onButtonClick = (button: ModalButton) => emit('modal-button-click', button)
const show = () => modal.value?.show()
const hide = () => modal.value?.hide()

defineExpose({
  show,
  hide
})
</script>

<script lang="ts">
export interface ModalButton {
  title: string;
  cssClass: string;
  value: any;
}
</script>
